<template>
	<div v-if="loading">
		<LoadingSpinnner />
	</div>
	<div v-else>
		<div class="d-flex justify-content-between mb-3">
			<button class="btn btn-light" @click="close">
				<font-awesome-icon :icon="['fas', 'chevron-left']" />
				{{ $t("planes.back") }}
			</button>
			<button class="btn btn-primary" @click="savePlane">
				<font-awesome-icon icon="check" />
				{{ $t("planes.save") }}
			</button>
		</div>
		<div class="row">
			<div class="col-md-12 ">
				<small class="d-block mb-3">{{ $t('custom-plane-form.mandatory-blocks') }}</small>

				<CustomPlaneFromPlane
					:isEditing="isEditing"
					:isMainEditor="canEdit"
					:canWrite="canWrite"
					:planeChanges="planeChanges"
					:msgs="msgs"
					:planeToSave="plane"
					@resetChange="resetChange"
				/>

				<CustomPlaneFormProfile
					:isEditing="isEditing"
					:isMainEditor="canEdit"
					:canWrite="canWrite"
					:msgs="msgs"
					:planeToSave.sync="plane"
				/>

				<CustomPlaneFormEquipments
					:msgs="msgs"
					:planeToSave="plane"
					:isEditing="isEditing"
					:canWrite="canWrite"
					:isMainEditor="canEdit"
					:planeChanges="planeChanges"
					@resetChange="resetChange"
				/>

				<CustomPlaneFormEmergencyInstruments
					:isEditing="isEditing"
					:isMainEditor="canEdit"
					:canWrite="canWrite"
					:planeChanges="planeChanges"
					:msgs="msgs"
					:planeToSave="plane"
					@resetChange="resetChange"
				/>

				<CustomPlaneFormCogData
					ref="cogForm"
					:isEditing="isEditing"
					:isMainEditor="canEdit"
					:planeChanges="planeChanges"
					:msgs="msgs"
					:planeToSave="plane"
					@resetChange="resetChange"
					:canWrite="canWrite"
				/>
			</div>
		</div>
		<div :class="{
			'justify-content-between': true,
			'mb-3': true,
			'd-flex': true,
		}">
			<button class="btn btn-danger" @click="removePlane" v-if="isEditing">
				<font-awesome-icon icon="minus" />
				{{ $t("planes.delete") }}
			</button>
			<div v-else>

			</div>
			<button class="btn btn-primary" @click="savePlane" >
				<font-awesome-icon icon="check" />
				{{ $t("planes.save") }}
			</button>
		</div>
	</div>
</template>

<script>
import { Fade } from "@egjs/flicking-plugins";
import LoadingSpinnner from "@/components/LoadingSpinner.vue";
import _ from "lodash";
import { scrollToTop } from "@/plugins/utils";
import CustomPlaneFromPlane from "@/components/plane/customPlaneForm/CustomPlaneFromPlane.vue";
import CustomPlaneFormProfile from "@/components/plane/customPlaneForm/CustomPlaneFormProfile.vue";
import CustomPlaneFormCogData from "@/components/plane/customPlaneForm/CustomPlaneFormCogData.vue";
import CustomPlaneFormEmergencyInstruments
	from "@/components/plane/customPlaneForm/CustomPlaneFormEmergencyInstruments.vue";
import CustomPlaneFormEquipments from "@/components/plane/customPlaneForm/CustomPlaneFormEquipments.vue";

export default {
	name: "PlaneView",
	components: {
		CustomPlaneFormEquipments,
		CustomPlaneFormEmergencyInstruments,
		CustomPlaneFormCogData,
		CustomPlaneFormProfile,
		CustomPlaneFromPlane,
		LoadingSpinnner,

	},
	data() {
		return {
			plugins: [new Fade()],
			/** @type {UserPlane|null} */
			userPlane: null,
			/** @type {Plane|null} */
			plane: null,
			/** @type {PlaneChange[]} */
			planeChanges: [],
			/** @type {PlaneChange[]} */
			savedPlaneChanges: [],
			loading: true,
			/** @type {User|null} */
			userData: null,
			options: {
				multi: true
			}
		};
	},
	props: {
		msgs: Object
	},
	watch: {
		computedPlane: {
			handler: function(newPlane, oldPlane) {
				if(!this.isEditing) return;
				if (this.canEdit) return;
				if (!newPlane) return;
				if (!oldPlane) return;
				if (_.isEqual(newPlane, oldPlane)) return;

				for (let key in newPlane) {
					const newVal = newPlane[key];
					const oldVal = oldPlane[key];
					if (!newVal || !oldVal) continue;

					let changed = !_.isEqual(newVal, oldVal);
					const change = this.planeChanges.find((change) => change.field === key);
					if (!change && !changed) {
						continue;
					}

					if (!change && changed) {
						this.planeChanges = [...this.planeChanges, {
							field: key,
							oldValue: JSON.stringify(oldVal),
							newValue: JSON.stringify(newVal)
						}];
						continue;
					}

					changed = !_.isEqual(JSON.parse(change.oldValue), newVal);
					if (!changed) {
						this.planeChanges = [...this.planeChanges.filter((change) => change.field !== key)];
						continue;
					}


					this.planeChanges = [...this.planeChanges.map((change) => {
						if (change.field === key) {
							return {
								...change,
								newValue: JSON.stringify(newVal)
							};
						}
						return change;
					})];
				}
			},
			deep: true
		}
	},
	computed: {
		computedPlane() { //This is a workaround to make sure that the watcher is called with the new and old value
			if (!this.plane) return null;
			return JSON.parse(JSON.stringify(this.plane));
		},
		canEdit() {
			if (!this.userData) return false;
			if (!this.userPlane && this.plane) return true;
			if (this.userPlane.editor) return true;
			return this.userPlane.operator;
		},
		isEditing() {
			return !!(this.userPlane && this.userPlane.id);
		},
		canWrite() {
			return this.isEditing || !this.userPlane;
		},
	},
	methods: {
		async removePlane() {
			if (await this.$confirm(this.$t("planes.deleteConfirm"))) {
				await this.$rest.detachFromPlane(this.plane.id);
				await this.$router.replace(`/planes`);
			}
		},
		resetChange(field, value) {
			this.plane[field] = value;
		},
		close() {
			this.$router.push(`/planes`);
		},
		async savePlane() {
			let resp;
			this.plane.registrationNumber = this.plane.registrationNumber.toUpperCase();
			console.log(this.plane.operatorEmail);
			if (this.isEditing) {
				resp = await this.$rest.modifyPlane({
					...this.plane,
					gravityPoints: JSON.stringify(this.plane.gravityPoints),
				});
			} else {
				resp = await this.$rest.addNewPlane({
					...this.plane,
					gravityPoints: JSON.stringify(this.plane.gravityPoints),
				});
			}
			if (!resp) {
				this.$emit( 'addMessage',{ messageKey: 'plane.saveError', severity: 'error' })
				scrollToTop(100);
				return;
			}
			await this.$router.push(`/planes`);
		},
		async loadUserPlane(id) {
			const up = await this.$rest.loadUserPlane(id);
			if (!up) return this.$router.replace(`/planes`);
			this.userPlane = up;
			this.plane = this.setPlaneDefaults(up.plane);

		},
		async loadChanges(id) {
			const changes = await this.$rest.loadUserPlaneChanges(id);
			if (!changes) return this.$router.replace(`/planes`);
			this.planeChanges = [...changes];
			this.savedPlaneChanges = [...changes];
		},
		async loadNewPlaneData() {
			const from = Number(this.$route.query.from);
			if (isNaN(from) || from <= 0) {
				this.resetPlane();
				this.loading = false;
				return;
			}
			const p = await this.$rest.loadPlane(from);
			if (!p) {
				this.resetPlane();
				this.loading = false;
				return;
			}
			this.plane = this.setPlaneDefaults({
				...this.plane,
				...p,
				registrationNumber: "",
				id: null,
			})


			this.loading = false;
		},
		resetPlane() {
			this.plane = this.setPlaneDefaults({});
			this.planeChanges = [];
			this.savedPlaneChanges = [];
		},
		/**
		 * @param {Plane} p
		 */
		setPlaneDefaults(p) {
			return {
				...p,
				producer: p.producer || "",
				model: p.model || "",
				registrationNumber: p.registrationNumber || "",
				turbulenceCategory: p.turbulenceCategory || "",
				operator: p.operator || "",
				colorAndNotation: p.colorAndNotation || "",
				planeModel: p.planeModel || "",
				speedUnit: p.speedUnit || "kmh",
				defaultSpeed: p.defaultSpeed || 0,
				altitudeUnit: p.altitudeUnit || "ft",
				defaultAltitude: p.defaultAltitude || 0,
				consumptionUnit: p.consumptionUnit || 'galh',
				defaultConsumption: p.defaultConsumption || 0,
				beaconType: p.beaconType || "MOTORPLANE",
				icaoEquipments: p.icaoEquipments || [],
				icaoSurveillances: p.icaoSurveillances || [],
				icaoPerfBasedNavList: p.icaoPerfBasedNavList || [],
				emergencyRadios: p.emergencyRadios || [],
				survivalEquipments: p.survivalEquipments || [],
				jackets: p.jackets || [],
				gravityXMin: p.gravityXMin || 80,
				gravityXMax: p.gravityXMax || 100,
				gravityYMin: p.gravityYMin || 1500,
				gravityYMax: p.gravityYMax || 2300,
				gravityYUnit: p.gravityYUnit || "lbs",
				gravityXUnit: p.gravityXUnit || "in",
				gravityPoints: (p.gravityPoints || []).filter((point) => point.xValue && point.yValue),
			}
		}
	},
	async beforeMount() {
		this.userData = this.$store.getters.getLoggedInUserData;
		const id = this.$route.params.id;
		if (!id) {
			await this.loadNewPlaneData();
			return;
		}
		await Promise.all([this.loadUserPlane(id), this.loadChanges(id)]);
		if (this.plane) {
			this.loading = false;
		}
	}
};

</script>

<style scoped>

</style>
